import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

import Button from './base/Button';
import Text from './base/Text';

const itemsPerPageOptions = [
  {
    label: "10 per page",
    value: 10
  },
  {
    label: "25 per page",
    value: 25
  },
  {
    label: "50 per page",
    value: 50
  },
  {
    label: "100 per page",
    value: 100
  }
]

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '45px',
    fontSize: '18px',
    fontFamily: 'Assistant',
    border: "none",
    outline: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Assistant', // Set your desired font family
    fontSize: '18px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Assistant', // Ensure consistency in selected value
    fontSize: '18px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: 'Assistant', // Ensure consistency in selected value
    fontSize: '18px',
  })
};

const PaginationFooter = ({ items, page, setPage, itemsPerPage, setItemsPerPage, nonSticky }: {
  items: any[],
  page: number,
  setPage: (n: number) => void
  itemsPerPage: number,
  setItemsPerPage: (n: number) => void,
  nonSticky?: boolean
}) => {

  const [lowerBound, setLowerBound] = useState<number>(1);
  const [upperBound, setUpperBound] = useState<number>(10);
  const [maxPage, setMaxPage] = useState<number>(1);

  useEffect(() => {
    setLowerBound(page * itemsPerPage + 1);
    setUpperBound(Math.min((page + 1) * itemsPerPage, items.length));
    setMaxPage(Math.ceil(items.length / itemsPerPage) - 1);
  }, [items, page, itemsPerPage])

  useEffect(() => {
    setPage(0);
  }, [itemsPerPage])

  return (
    <div style={{
      position: nonSticky ? undefined : "absolute",
      bottom: nonSticky ? undefined : "25px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "calc(100% - 85px)"
    }}>
      <Text size={20}>
        { items.length > 1 && `Showing ${lowerBound}-${upperBound} of ${items.length}` }
        { items.length == 1 && `Showing 1 of 1` }
        { items.length == 0 && `Showing 0 of 0`}
      </Text>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "30px"
      }}>
        <Select
          value={itemsPerPageOptions.find(c => c.value === itemsPerPage)}
          onChange={(option) => {
            setItemsPerPage(option?.value ?? 10);
          }}
          options={itemsPerPageOptions}
          styles={customStyles}
        />
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}>
          <img
            style={{ width: "20px", rotate: "90deg", opacity: page == 0 ? "0.5" : undefined, cursor: "pointer" }}
            src={process.env.PUBLIC_URL + "/assets/down-arrow.png"}
            onClick={() => {
              if (page > 0) setPage(page - 1);
            }}
          />
          <Text size={20}>
            {page + 1}
          </Text>
          <img
            style={{ width: "20px", rotate: "-90deg", opacity: page == maxPage ? "0.5" : undefined, cursor: "pointer" }}
            src={process.env.PUBLIC_URL + "/assets/down-arrow.png"}
            onClick={() => {
              if (page < maxPage) setPage(page + 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaginationFooter;
