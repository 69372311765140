import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import Text from './base/Text';
import { deleteCoverageEntry, editCoverageEntry, reassignClient, retrieveClients } from '../requests';
import { useCookies } from 'react-cookie';
import Input from './Input';
import Button from './base/Button';
import { ClientType } from '../LandingPage';
import { DocumentType } from './Document';
import LoadingSpinner from './base/Spinner';

const ClientItem = ({ c, setSelectedClient }: { c: ClientType, setSelectedClient: (c: ClientType) => void }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "30px",
      borderRadius: "4px",
      cursor: "pointer",
      padding: "10px",
      backgroundColor: isHovered ? "#D9DCF7" : "white"
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setSelectedClient(c)}
    >
      <img src={process.env.PUBLIC_URL + "/assets/city-buildings.png"} alt="" style={{ width: "25px", height: "25px", marginRight: 10 }} />
      <Text size={20}>
        {c.name}
      </Text>
    </div>
  )

}

const ReassignClient = ({ d, retrieveDocuments, isList }: {
  d: DocumentType,
  retrieveDocuments: (() => void) | undefined,
  isList?: boolean
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [loading, setLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<ClientType | null>(null);
  const [clients, setClients] = useState<ClientType[]>([]);

  const retrieveAllClients = async () => {
    const resp = await retrieveClients(cookies['user-id']);
    const clnts = resp.body["clients"].map(c => ({
      id: c["client_id"],
      name: c["name"]
    }));

    setClients([...clnts, { id: "default_client", name: "General" }]);
  }

  const reassign = async () => {
    setLoading(true);
    await reassignClient(cookies['user-id'], d.instanceId ?? "", selectedClient?.id ?? "");
    retrieveDocuments && retrieveDocuments();
    await new Promise(r => setTimeout(r, 2000));
    setLoading(false);
  }

  useEffect(() => {
    retrieveAllClients();
    setIsVisible(true);
  }, [d])
  
  return (
    <div style={{
      position: "absolute",
      left: isList ? undefined : "175px",
      right: isList ? "0px" : undefined,
      top: isList ? "35px" : "30px",
      backgroundColor: "white",
      borderRadius: "8px",
      width: "200px",
      maxHeight: "300px",
      overflowY: "scroll",
      padding: "10px",
      border: "1px solid gray",
      zIndex: 2000,
      opacity: isVisible ? 1 : 0, // Control the opacity
      transition: 'opacity 0.5s ease-in-out' // Add the transition effect for fade-in
    }}>
      { !selectedClient && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "50px"
          }}>
            <Text size={20}>
              Reassign to a different account...
            </Text>
          </div>
          { clients.filter(c => (c.id != d.clientId)).map(c => (
            <ClientItem c={c} setSelectedClient={setSelectedClient}/>
          ))}
        </>
      )}
      { selectedClient && (
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px"
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "50px"
          }}>
            <Text size={20}>
              About to reassign to the following account:
            </Text>
          </div>
          <ClientItem c={selectedClient} setSelectedClient={setSelectedClient}/>
          <div style={{
            display: "flex",
            flexDirection: "column",
            //height: "50px"
          }}>
            <Text size={20}>
              WARNING: This will also reassign all reports and proposals associated with this document.
            </Text>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "10px",
            alignItems: "center"
            //height: "50px"
          }}>
            <Button padding="5px" color="white" border="1px solid black" onClick={() => setSelectedClient(null)}>
              <Text size={14}>
                Cancel
              </Text>
            </Button>
            { !loading && (
              <Button padding="5px" onClick={reassign}>
                <Text size={14} color="white">
                  Reassign
                </Text>
              </Button>
            )}
            { loading && (
              <LoadingSpinner />
            )}
          </div>
        </div>
      )}
    </div>

  );
};

export default ReassignClient;
