import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
//import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbar } from "react-scrollbars-custom";
import Select, { components } from 'react-select';

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";

import LoadingSpinner from "./components/base/Spinner";
import Container from "./components/Container";
import Document, { DocumentStatus, DocumentType } from "./components/Document";
import { DocumentsContainer } from "./Documents";
import Button from "./components/base/Button";
import OpenReportButton from "./components/OpenReportButton";

const Reports = React.memo(({ clientId, setClientId, agencyId, documents, retrieveDocuments, loadingDocuments, onDocumentClick, setErrorMessage, isSidebarOpen, setFirstAnalysisModalOpen }: { 
  clientId: string,
  agencyId: string,
  setClientId: (s: string) => void,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  loadingDocuments: boolean,
  onDocumentClick: (d: DocumentType) => void,
  setErrorMessage: (s: string) => void,
  isSidebarOpen: boolean,
  setFirstAnalysisModalOpen: (b: boolean) => void
}) => {

  return (
    <div style={{
      position: "fixed",
      height: "100%",
      top: "100px",
      left: isSidebarOpen ? "205px" : "40px",
      padding: "53px 60px 0px 60px",
      transition: "left 0.3s ease"
    }}>
      <Scrollbar style={{ width: isSidebarOpen ? "calc(100vw - 270px)" : "calc(100vw - 105px)", height: "100%", transform: "translateY(-60px)", transition: "width 0.3s ease" }}>
      <div style={{ transform: "translateY(60px)"}}>
        <Text size={40} color="#787878">
          Reports
        </Text>
        <Space px={25} />
        <Container key="analysisContainer" minHeight="375px" title="Policy Reports" zIndex={8} width={isSidebarOpen ? "calc(100vw - 400px)" : "calc(100vw - 235px)"} rightButton={documents.filter(d => d.category == "analysis").length > 0 ? <OpenReportButton setFirstAnalysisModalOpen={setFirstAnalysisModalOpen} /> : undefined}>
          { loadingDocuments && (
            <>
              <Space px={140} />
              <LoadingSpinner />
            </>
          )}
          { !loadingDocuments && (
            <DocumentsContainer clientId={clientId} category="analysis" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} onFirstClick={() => setFirstAnalysisModalOpen(true)}/>
          )}
        </Container>
        <Space px={300} />
      </div>
      </Scrollbar>
    </div>
  );
});

export default Reports;
  