import React, { useState } from "react";
import Button from "./base/Button";
import Text from "./base/Text";
import Space from "./base/Space";

const ComparisonOption = ({ s, onClick }: { s: string, onClick: () => void }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      //gap: "3px",
      justifyContent: "center",
      alignItems: "center",
      height: "22px",
      borderRadius: "4px",
      cursor: "pointer",
      padding: "5px",
      backgroundColor: isHovered ? "#D9DCF7" : "white"
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick()}
    >
      <img src={process.env.PUBLIC_URL + "/assets/compare-blue.png"} alt="" style={{ width: "23px", height: "23px", marginRight: 10 }} />
      <Text size={18}>
        {s}
      </Text>
    </div>
  )

}

const OpenComparisonButton = React.memo(({ setFirstComparisonModalOpen, setDocumentType, setSecondDocumentType }: { 
  setFirstComparisonModalOpen: (b: boolean) => void,
  setDocumentType: (s: string) => void,
  setSecondDocumentType: (s: string) => void,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onClick = (selection: string) => {
    if (selection == "quote-quote") {
      setDocumentType("quote");
      setSecondDocumentType("quote");
    } else if (selection == "quote-policy") {
      setDocumentType("quote");
      setSecondDocumentType("policy");
    } else if (selection == "policy-policy") {
      setDocumentType("policy");
      setSecondDocumentType("policy");
    } else if (selection == "binder-policy") {
      setDocumentType("binder");
      setSecondDocumentType("policy");
    } else if (selection == "quote-binder") {
      setDocumentType("quote");
      setSecondDocumentType("binder");
    }

    setFirstComparisonModalOpen(true);
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      cursor: "pointer",
      position: "relative"
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
        <img src={process.env.PUBLIC_URL + "/assets/plus-hollow.png"} style={{ width: "13px", height: "13px" }} />
        <Text size={18} weight={600}>
          New Comparison
        </Text>
        <img style={{ width: "17px", height: "17px", marginLeft: "-5px", rotate: isHovered ? "90deg" : undefined, transition: "rotate 0.3s ease" }} src={ process.env.PUBLIC_URL + "/assets/right-arrow.png"}/>
      </div>
        <div style={{
          position: "absolute",
          top: "30px",
          //left: "170px",
          left: "-10px",
          backgroundColor: "white",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          //borderRadius: "8px",
          width: "180px",
          maxHeight: "300px",
          overflowY: "scroll",
          //padding: "10px",
          //border: "1px solid #E0E0E0",
          borderRadius: "4px",
          zIndex: 2000,
          opacity: isHovered ? 1 : 0, // Control the opacity
          transition: 'opacity 0.5s ease-in-out' // Add the transition effect for fade-in
        }}>
            <ComparisonOption s="Quote-to-quote" onClick={() => onClick("quote-quote")} />
            <ComparisonOption s="Quote-to-policy" onClick={() => onClick("quote-policy")} />
            <ComparisonOption s="Quote-to-binder" onClick={() => onClick("quote-binder")} />
            <ComparisonOption s="Policy-to-policy" onClick={() => onClick("policy-policy")} />
            <ComparisonOption s="Binder-to-policy" onClick={() => onClick("binder-policy")} />
        </div>
    </div>
  )
});

/*
            <Text size={20}>
              Compare a...
            </Text>
            <Space px={10} />*/
export default OpenComparisonButton;
