import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";

import Text from './base/Text';
import Space from './base/Space';
import { s3Presign, updateDocument, uploadDocument } from '../requests';

interface PresignData {
  url: string;
  fields: {
    [key: string]: string | Blob; // Specifying that fields can be string or Blob
  };
}

const UploadFile = ({ retrieveDocuments, category, shiftUp, clientId, associatedDocumentId, isList, setErrorMessage }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [localCategory, setLocalCategory] = useState<string>("");
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    setLocalCategory(category);
  }, [category])

  const onFileUpload = async (file) => {
    try {
      const maxSize = 25 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage('Warning: File size exceeds 25 MB. Upload could take several minutes.');
      }
      const fileKey = `${Date.now()}_${file.name}`;
      const resp = await uploadDocument(cookies['user-id'], fileKey, category, clientId, associatedDocumentId, file.name);
      await retrieveDocuments();

      // Get the pre-signed URL from the Flask backend
      const presignResponse = await s3Presign(cookies['user-id'], fileKey);
      const presignData: PresignData = presignResponse.body["url"];

      // Prepare the form data for posting the PDF file
      const formData = new FormData();
      Object.entries(presignData.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);

      // Post the file to the S3 bucket using the pre-signed URL
      const uploadResponse = await fetch(presignData.url, {
        method: 'POST',
        body: formData
      });

      if (uploadResponse.ok) {
        await updateDocument(cookies['user-id'], resp.body['document_instance_id'], "uploaded", "", fileKey);
      } else {
        throw new Error('Upload failed');
      }
    } catch (err) {
      console.error('Error uploading file:', err);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      onFileUpload(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        id={`${category}FileInput`}
        accept=".pdf"
      />
      { !isList && (
        <label htmlFor={`${category}FileInput`} style={{ 
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "175px",
          gap: "20px",
          cursor: "pointer",
          marginBottom: shiftUp ? "35px" : undefined,
          marginTop: shiftUp ? undefined : "20px",
        }}>
          <img style={{ width: "45px", height: "45px" }} src={process.env.PUBLIC_URL + "/assets/upload.png"} />
          <Text size={20} align="center">
            Upload PDF from computer
          </Text>
        </label>
      )}
      { isList && (
        <label htmlFor={`${category}FileInput`} style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          gap: "5px",
          padding: "5px 10px",
          //border: "1px solid gray",
          borderRadius: "8px",
          cursor: "pointer",
          transition: "background-color 0.25s",
          backgroundColor: isHovered ? "#E0E0E0" : "#EFEFEF"
        }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div style={{ width: "5%"}}>
            <img style={{ width: "25px", height: "25px" }} src={process.env.PUBLIC_URL + "/assets/upload.png"} />
          </div>
          <Text size={20} align="center">
            Upload PDF from computer
          </Text>
        </label>
      )}
    </div>
  );
};

export default UploadFile;

