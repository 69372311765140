import React, { useEffect, useState } from 'react';

import Button from './base/Button';
import Text from './base/Text';
import Input from './Input';

const Search = ({ onSearch, query, setQuery }: { onSearch: () => void, query: string, setQuery: (s: string) => void }) => {

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: "10px",
      //width: "300px",
      border: "1px solid black",
      borderRadius: "16px",
      padding: "5px",
      width: "320px",
    }}>
      <img
        style={{ width: "30px" }}
        src={process.env.PUBLIC_URL + "/assets/search.png"}
      />
      <Input value={query} setValue={setQuery} extraStyles={{ width: "300px", borderRadius: "0px" }} />
    </div>
  );
};

export default Search;
