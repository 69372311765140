import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentStatus, DocumentType } from "./Document";
import Button from "./base/Button";
import { comparePolicyCoverage } from "../requests";
import { useCookies } from "react-cookie";
import LoadingSpinner from "./base/Spinner";
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './base/Button.css';

import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { DocumentsContainer } from "../Documents";
import DocumentTypeDropdown from "./DocumentTypeDropdown";

const FirstComparisonModal = ({ setModalOpen, modalOpen, document, documents, setDocument, retrieveDocuments, setErrorMessage, secondDocument, setSecondDocument, agencyId, clientId, setClientId, documentType, setDocumentType, secondDocumentType, setSecondDocumentType }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  document: DocumentType | null,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  setDocument: (d: DocumentType | null) => void,
  secondDocument: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
  documentType: string,
  setDocumentType: (s: string) => void,
  secondDocumentType: string,
  setSecondDocumentType: (s: string) => void,
  setErrorMessage: (s: string) => void,
  agencyId: string,
  clientId: string,
  setClientId: (s: string) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [page, setPage] = useState<string>("compare");
  const [generationStarted, setGenerationStarted] = useState<string | null>(null);
  const [objective, setObjective] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onModalCloseInternal = () => {
    setPage("compare");
    setGenerationStarted(null);
  }

  const onGenerate = async () => {
    setGenerationStarted("started");
    await comparePolicyCoverage(cookies['user-id'], [document?.instanceId ?? "", secondDocument?.instanceId ?? ""], true, [documentType, secondDocumentType]);
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const onDocumentClick = (d2: DocumentType) => {
    setSelectedDocument(d2);
  }
  
  const onCompareSelect = async () => {
    if (objective == "first") {
      setDocument(selectedDocument);
    } else {
      setSecondDocument(selectedDocument);
    }
    setPage("compare");
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="firstComparisonModal" onModalClose={() => {
      onModalCloseInternal();
    }} extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "90%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative"
    }}>
      { page == "compare" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select two documents to compare.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
              { !document && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: "280px",
                  height: "280px",
                  border: "1px solid black",
                  cursor: "pointer"
                }} onClick={() => {
                  setObjective("first");
                  setPage("selecting");
                }}>
                  <img style={{ width: "75px", height: "75px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
                </div>
              )}
              { document && (
                <div style={{
                  position: "relative"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    width: "280px",
                    height: "280px",
                  }}>
                    <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                    <Text size={30} weight={600} align="center">
                      {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
                    </Text>
                  </div>
                  <img style={{ width: "50px", height: "50px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => setDocument(null)} />
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center"}}>
                <Text size={25}>
                  Document Type: 
                </Text>
                <DocumentTypeDropdown documentType={documentType} setDocumentType={setDocumentType} />
              </div>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
              { !secondDocument && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: "280px",
                  height: "280px",
                  border: "1px solid black",
                  cursor: "pointer"
                }} onClick={() => {
                  setObjective("second");
                  setPage("selecting");
                }}>
                  <img style={{ width: "75px", height: "75px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
                </div>
              )}
              { secondDocument && (
                <div style={{
                  position: "relative"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    height: "280px",
                  }}>
                    <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                    <Text size={30} weight={600} align="center">
                      {secondDocument?.name && secondDocument?.name.length > 17 ? secondDocument?.name.slice(0, 17) + "..." : secondDocument?.name}
                    </Text>
                  </div>
                  <img style={{ width: "50px", height: "50px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => setSecondDocument(null)} />
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
                <Text size={25}>
                  Document Type: 
                </Text>
                <DocumentTypeDropdown documentType={secondDocumentType} setDocumentType={setSecondDocumentType} />
              </div>
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
                  onModalCloseInternal();
                  setModalOpen(false);
                }}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" greyed={!document || !secondDocument} onClick={onGenerate}>
                  <Text size={20} weight={600} color="white">
                    Generate Comparison
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "selecting" && (
          <>
            <Text size={35} weight={600}>
              Select a document to compare
            </Text>
            <Divider />
            <DocumentsContainer clientId={clientId} category="policy" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} selecting selectedDocument={selectedDocument ?? undefined} includePercent />            
            <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "flex-end" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare")}>
                <Text size={20} weight={600} color="#000000">
                  Cancel
                </Text>
              </Button>
              <Button greyed={!selectedDocument} onClick={onCompareSelect}>
                <Text size={16} weight={600} color="white">
                  Select Document
                </Text>
              </Button>
            </div>
          </>
      )}
    </Modal>
  )
};

export default FirstComparisonModal;
