import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
//import { Analytics } from '@vercel/analytics/react';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbar } from "react-scrollbars-custom";

import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";

import LoadingSpinner from "./components/base/Spinner";
import Container from "./components/Container";
import Document, { DocumentStatus, DocumentType } from "./components/Document";
import { DocumentsContainer } from "./Documents";

const Proposals = React.memo(({ clientId, setClientId, agencyId, documents, retrieveDocuments, loadingDocuments, onDocumentClick, setErrorMessage, isSidebarOpen }: { 
  clientId: string,
  agencyId: string,
  setClientId: (s: string) => void,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  loadingDocuments: boolean,
  onDocumentClick: (d: DocumentType) => void,
  setErrorMessage: (s: string) => void,
  isSidebarOpen: boolean
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div style={{
      position: "fixed",
      height: "100%",
      top: "100px",
      left: isSidebarOpen ? "205px" : "40px",
      padding: "53px 60px 0px 60px",
      transition: "left 0.3s ease"
    }}>
      <Scrollbar style={{ width: isSidebarOpen ? "calc(100vw - 270px)" : "calc(100vw - 105px)", height: "100%", transform: "translateY(-60px)", transition: "width 0.3s ease" }}>
      <div style={{ transform: "translateY(60px)"}}>

      <Text size={40} color="#787878">
        Proposals
      </Text>
      <Space px={25} />
      <Container key="proposalContainer" minHeight="375px" title="Policy Proposals" zIndex={7} width={isSidebarOpen ? "calc(100vw - 400px)" : "calc(100vw - 235px)"}>
        { loadingDocuments && (
          <>
            <Space px={140} />
            <LoadingSpinner />
          </>
        )}
        { !loadingDocuments && (
          <DocumentsContainer clientId={clientId} category="proposal" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} />
        )}
      </Container>
      <Space px={300} />
      </div>
      </Scrollbar>
    </div>
  );
});

export default Proposals;