import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '150px',  // Set your desired width here
    height: '45px',
    fontSize: '18px',
    fontFamily: 'Assistant',
    color: 'black'
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Assistant', // Set your desired font family
    fontSize: '25px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Assistant', // Ensure consistency in selected value
    fontSize: '25px',
    color: 'black'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: 'Assistant', // Ensure consistency in selected value
    fontSize: '25px',
    color: 'black'
  })
};

const documentTypes = [
  {
    label: "Quote",
    value: "quote"
  },
  {
    label: "Policy",
    value: "policy"
  },
  {
    label: "Binder",
    value: "binder"
  },
];

const DocumentTypeDropdown = ({ documentType, setDocumentType }: {
  documentType: string,
  setDocumentType: (s: string) => void
}) => {
  const navigate = useNavigate();

  return (
    <Select
      value={documentTypes.find(c => c.value === documentType)}
      onChange={(option) => {
        setDocumentType(option?.value ?? "");
      }}
      options={documentTypes}
      styles={customStyles}
    />
  )
};

export default DocumentTypeDropdown;
