import React, { useState } from "react";
import Button from "./base/Button";
import Text from "./base/Text";

const OpenReportButton = React.memo(({ setFirstAnalysisModalOpen }: { setFirstAnalysisModalOpen: (b: boolean) => void }) => {
  return (
    <Button padding="7px" color="white" border="1px solid black" onClick={() => setFirstAnalysisModalOpen(true)}>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
        <img src={process.env.PUBLIC_URL + "/assets/plus-hollow.png"} style={{ width: "13px", height: "13px" }} />
        <Text size={18} weight={600}>
          New Report
        </Text>
      </div>
    </Button>
  )
});

export default OpenReportButton;