import React, { useState, useRef, useEffect } from "react";

import Text from "./base/Text";
import "./AppHeader.css";

const BottomBar = ({ setPrivacyModalOpen, setTermsModalOpen, displayRight }) => {

  return (
    <div style={{
      display: "flex",
      position: "fixed",
      bottom: "32px",
      width: "calc(100vw - 64px)",
      left: "32px",
      justifyContent: "space-between",
    }}>
      <Text color="light-gray" size="16px" weight={400}>
        ©Coverflow, 2024
      </Text>
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
      }}>
        { displayRight && (
          <>
            <div style={{ cursor: "pointer" }} onClick={() => setTermsModalOpen(true)}>
              <Text color="light-gray" size="16px" weight={400}>
                Terms
              </Text>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => setPrivacyModalOpen(true)}>
              <Text color="light-gray" size="16px" weight={400}>
                Privacy
              </Text>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => window.open("mailto:matthew@coverflow.tech", "_blank")}>
              <Text color="light-gray" size="16px" weight={400}>
                Contact
              </Text>
            </div>
          </>
        )}
      </div>
    </div>
  )
};

export default BottomBar;
