import React, { useState } from 'react';
import Text from './base/Text';

const SidebarOption = ({ text, isPage, page, image, setPage, isOpen }: { text: string, image: string, isPage: boolean, page: string, setPage: (s: string) => void, isOpen: boolean }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  
  return (
    (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          height: "40px",
          cursor: "pointer",
          borderRadius: "8px",
          transition: "background-color 0.25s",
          backgroundColor: isPage ? "#E7E7E7" : (isHovered ? "#ECECEC" : undefined)
        }}
        onClick={() => setPage(page)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={{
          marginLeft: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isOpen ? "flex-start" : "flex-end",
          transform: isOpen ? undefined : "translateX(155px)",
          transition: "transform 0.3s ease",
          gap: "10px",
        }}>
          <img
            style={{ height: "23px" }}
            src={process.env.PUBLIC_URL + `/assets/${isPage ? `${image}-blue` : `${image}-black`}.png`}
          />
          { isOpen && (
            <Text size={20} weight={isPage ? 700 : 400} color={isPage ? "#4854F4" : undefined}>
              {text}
            </Text>
          )}
        </div>
      </div>
    )
  );
};

export default SidebarOption;
