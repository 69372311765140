
import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentStatus, DocumentType } from "./Document";
import Button from "./base/Button";
import { analyzePolicy, comparePolicyCoverage, deleteDocument, fetchMatchedPairs, generateEditedReport, getDocumentResults, getPresignedUrl, setDisplayName, updateCoverageLines } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import LoadingSpinner from "./base/Spinner";
import { Viewer, Worker, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Space from "./base/Space";
import './base/Button.css';

// Import styles
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { highlightPlugin, HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { Trigger } from '@react-pdf-viewer/highlight';
import ResultItem from "./ResultItem";
import Dropdown from "./base/Dropdown";
import PairItem from "./PairItem";
import MarkdownPage from "./MarkdownPage";

// test update
const coverageLines = [
  "Cyber",
  "D&O",
  "Professional Liability",
  "Commercial Property",
  "General Liability",
  "BOP",
  "Garage",
  "EPL",
  "Crime",
  "Pollution Liability",
  "Logging",
  "Builder's Risk",
  "Commercial Auto",
  "Inland Marine",
  "Umbrella"
]

// interface HighlightArea {
//   height: number;
//   left: number;
//   pageIndex: number;
//   top: number;
//   width: number;
// }


// coverageType: coverage, endorsement, schedule, generic
// coverageLine: only relevant to coverage/endorsements
// what line it belongs to

// keys: all the values to display under result that's editable

export interface ResultWithReference {
  // The generated unique identifier
  id: string;

  // The result content
  coverageName: string;

  coverageType: string;

  coverageLine: string;

  keys: any;

  // The list of highlight areas
  highlightArea: HighlightArea;

  // String of Page Ref information
  pageIndex: string;
}

export interface MatchedPair {

  id: string;

  similar: boolean;

  firstVal: ResultWithReference;

  secondVal: ResultWithReference;

}

const scheduleLineOptions = [
  'form_schedule', 
  'umbrella_schedule',
  'vehicle_list',
  'general_mod',
  'farm_schedule',
  'liquor_schedule',
  'inland_schedule',
  'address_list',
  'workers_comp_schedule',
  'garage_addresses',
  'driver_list',
  'contractor_property_list'
];


const ComparisonModal = ({ setModalOpen, modalOpen, document, retrieveDocuments, setSuccessMessage, setErrorMessage, secondDocument, documentType, secondDocumentType }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  document: DocumentType | null,
  retrieveDocuments: () => void,
  secondDocument: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
  setSuccessMessage: (s: string) => void,
  setErrorMessage: (s: string) => void,
  documentType: string,
  secondDocumentType: string
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [page, setPage] = useState<string>("normal");
  const [generationStarted, setGenerationStarted] = useState<string | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [secondPresignedUrl, setSecondPresignedUrl] = useState<string>("");
  const [deleteButtonClicked, setDeleteButtonClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [matchedPairs, setMatchedPairs] = useState<MatchedPair[]>([]);
  const [filteredMatchedPairs, setFilteredMatchedPairs] = useState<MatchedPair[]>([]);
  const [selectedPairId, setSelectedPairId] = useState<string>("");
  const [selectedPairIndex, setSelectedPairIndex] = useState<number>(0);
  const [selectedResult, setSelectedResult] = useState<ResultWithReference[]>([]);
  const [secondSelectedResult, setSecondSelectedResult] = useState<ResultWithReference[]>([]);
  const [selectedLine, setSelectedLine] = useState<string>("");
  const [selectedCoverageType, setSelectedCoverageType] = useState<string>("generic");
  const [refreshEntries, setRefreshEntries] = useState<boolean>(false);
  const [lineOptions, setLineOptions] = useState<string[]>([]);
  const [scheduleResult, setScheduleResult] = useState<ResultWithReference | null>(null);
  const [localDocument, setLocalDocument] = useState<DocumentType | null>(null);
  const [localSecondDocument, setLocalSecondDocument] = useState<DocumentType | null>(null);

  const onScheduleClick = (r: ResultWithReference) => {
    setScheduleResult(r);
    setPage("markdown");
  }

  const onGenerate = async () => {
    await comparePolicyCoverage(cookies['user-id'], [localDocument?.instanceId ?? "", localSecondDocument?.instanceId ?? ""], true, [documentType, secondDocumentType]);
    
    retrieveDocuments();
    setModalOpen(false);
    setDeleteButtonClicked(false);
    setPage("normal");
    setPresignedUrl("");
    setGenerationStarted(null);
  }

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {selectedResult.map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: 'yellow',
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const secondRenderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {secondSelectedResult.map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: 'yellow',
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({renderHighlights, trigger: Trigger.None});
  const secondHighlightPluginInstance = highlightPlugin({renderHighlights: secondRenderHighlights, trigger: Trigger.None});
  const { jumpToHighlightArea } = highlightPluginInstance;
  const { jumpToHighlightArea: secondJumpToHighlightArea } = secondHighlightPluginInstance;

  useEffect(() => {
    const findPairById = (pairs: MatchedPair[], id: string): MatchedPair | undefined => {
      return pairs.find(pair => pair.id === id);
    };
    

    async function updateHighlights() {
      const foundPair = findPairById(matchedPairs, selectedPairId);
      if (foundPair === undefined) {
        console.log("NOTHING CLICKED, RESULT NOT FOUND")
      } else {
        console.log("FOUND PAIR");
        console.log(foundPair);
        setSelectedResult([foundPair.firstVal]);
        setSecondSelectedResult([foundPair.secondVal]);
      }
    }

    updateHighlights();

    return () => {};

  }, [matchedPairs, selectedPairId])

  const translateResultsToReactInterface = (results: any[]): MatchedPair[] => {
    return results.map((result) => {

      const similar = result.similar;
      const first_val = result.first_val;
      const second_val = result.second_val;

      // similar, first_val, second_val
      return {
        id: result.matched_pair_id,
        similar: similar,
        firstVal: {
          id: first_val.coverage_id ?? '',
          coverageName: first_val.coverage_name ?? '',
          coverageType: first_val.coverage_type ?? '',
          coverageLine: (
            scheduleLineOptions.includes(first_val.coverage_line)
              ? ""
              : (first_val.coverage_line ?? '')
          ),
          keys: first_val.keys ?? {},
          highlightArea: first_val.highlight_area ? {
            height: first_val.highlight_area.height ?? 0,
            width: first_val.highlight_area.width ?? 0,
            left: first_val.highlight_area.left ?? 0,
            top: first_val.highlight_area.top ?? 0,
            pageIndex: first_val.highlight_area.page_index ?? 0
          } : {
            height: 0,
            width: 0,
            left: 0,
            top: 0,
            pageIndex: 0
          },
          pageIndex: first_val.page_index ?? ''
        },
        secondVal: {
          id: second_val.coverage_id ?? '',
          coverageName: second_val.coverage_name ?? '',
          coverageType: second_val.coverage_type ?? '',
          coverageLine: (
            scheduleLineOptions.includes(second_val.coverage_line)
              ? ""
              : (second_val.coverage_line ?? '')
          ),
          keys: second_val.keys ?? {},
          highlightArea: second_val.highlight_area ? {
            height: second_val.highlight_area.height ?? 0,
            width: second_val.highlight_area.width ?? 0,
            left: second_val.highlight_area.left ?? 0,
            top: second_val.highlight_area.top ?? 0,
            pageIndex: second_val.highlight_area.page_index ?? 0
          } : {
            height: 0,
            width: 0,
            left: 0,
            top: 0,
            pageIndex: 0
          },
          pageIndex: second_val.page_index ?? ''
        }
        
      };
    });
  };

  useEffect(() => {
    if (document?.instanceId && document.instanceId.localeCompare(secondDocument?.instanceId ?? "") < 0) {
      setLocalDocument(document);
      setLocalSecondDocument(secondDocument);
    } else {
      setLocalDocument(secondDocument);
      setLocalSecondDocument(document);
    }

  }, [document, secondDocument])

  useEffect(() => {
    
    async function retrieveDocumentResults() {
      if (modalOpen) {
        try {
          setLoading(true);
          let resp = await fetchMatchedPairs(cookies['user-id'], [localDocument?.instanceId ?? "", localSecondDocument?.instanceId ?? ""]);
          let results = [...resp.body["similar"], ...resp.body["diff"]];
          if (results.length == 0) {
            await comparePolicyCoverage(cookies['user-id'], [localDocument?.instanceId ?? "", localSecondDocument?.instanceId ?? ""], true, [documentType, secondDocumentType]);
            while (results.length == 0) {
              await new Promise(r => setTimeout(r, 3000));
              resp = await fetchMatchedPairs(cookies['user-id'], [localDocument?.instanceId ?? "", localSecondDocument?.instanceId ?? ""]);
              results = [...resp.body["similar"], ...resp.body["diff"]];
            }
          }
          const translatedResults = translateResultsToReactInterface(results);
          setMatchedPairs(translatedResults);
          let newLines: string[] = [];
          for (let i = 0; i < translatedResults.length; i++) {
            const newLine1 = translatedResults[i].firstVal.coverageLine;
            const newLine2 = translatedResults[i].secondVal.coverageLine;
            if (newLine1 != "" && !newLines.includes(newLine1)) {
              newLines.push(newLine1);
            }
            if (newLine2 != "" && !newLines.includes(newLine2)) {
              newLines.push(newLine2);
            }
          }
          setLineOptions(newLines);
          setLoading(false);
        } catch (error) {
          console.error("Failed to retrieve matched  pairs:", error);
        }
      }
    }
  
    retrieveDocumentResults(); // Initial call

    if (refreshEntries) {
      setRefreshEntries(false);
    }
  
    return () => {};
  
  }, [localDocument, localDocument?.instanceId, localDocument?.category, modalOpen]);

  useEffect(() => {
    async function refreshDocumentResults() {
      if (modalOpen) {
        try {
          let resp = await fetchMatchedPairs(cookies['user-id'], [localDocument?.instanceId ?? "", localSecondDocument?.instanceId ?? ""]);
          let results = [...resp.body["similar"], ...resp.body["diff"]];
          const translatedResults = translateResultsToReactInterface(results);
          setMatchedPairs(translatedResults);
          let newLines: string[] = [];
          for (let i = 0; i < translatedResults.length; i++) {
            const newLine1 = translatedResults[i].firstVal.coverageLine;
            const newLine2 = translatedResults[i].secondVal.coverageLine;
            if (newLine1 != "" && !newLines.includes(newLine1)) {
              newLines.push(newLine1);
            }
            if (newLine2 != "" && !newLines.includes(newLine2)) {
              newLines.push(newLine2);
            }
          }
          setLineOptions(newLines);
        } catch (error) {
          console.error("Failed to retrieve matched  pairs:", error);
        }
      }
    }
  
    refreshDocumentResults(); // Initial call
    setRefreshEntries(false);
  
    return () => {};
  
  }, [refreshEntries]);

  useEffect(() => {
    setFilteredMatchedPairs(
      matchedPairs.filter(
        p => (
          (p.firstVal.coverageLine == selectedLine && p.firstVal.coverageType == selectedCoverageType) || 
          (p.firstVal.coverageType == "generic" && selectedCoverageType == "generic") ||
          (p.secondVal.coverageLine == selectedLine && p.secondVal.coverageType == selectedCoverageType) || 
          (p.secondVal.coverageType == "generic" && selectedCoverageType == "generic") || 
          (p.firstVal.coverageType == "schedule" && selectedCoverageType == "schedule") ||
          (p.secondVal.coverageType == "schedule" && selectedCoverageType == "schedule")
        )
      )
    );
    console.log(matchedPairs);
  }, [matchedPairs, selectedLine, selectedCoverageType])

  useEffect(() => {
    let intervalId;
  
    async function retrievePresignedUrls() {
      if (modalOpen) {
        try {
          const resp = await getPresignedUrl(cookies['user-id'], localDocument?.link ?? "", localDocument?.instanceId ?? "", false, false);
          const resp2 = await getPresignedUrl(cookies['user-id'], localSecondDocument?.link ?? "", localSecondDocument?.instanceId ?? "", false, false);
          setPresignedUrl(resp.body["url"]);
          setSecondPresignedUrl(resp2.body["url"]);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }

  
    retrievePresignedUrls(); // Initial call
  
    if (modalOpen) {
      intervalId = setInterval(retrievePresignedUrls, 59 * 60 * 1000); // Set interval for 59 minutes
    }
  
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or dependencies change
    };
  
  }, [localDocument, localDocument?.instanceId, localDocument?.category, modalOpen]);

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="comparisonModal" onModalClose={() => {
      setPage("normal");
      setDeleteButtonClicked(false);
    }} extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "90%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative"
    }}>
      { page == "normal" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: 'center',
              justifyContent: "flex-start",
              width: "90%",
              gap: "20px"
            }}>
              <div style={{ maxWidth: "50%" }}>
                <Text size={35} weight={600}>
                  Policy Comparison
                </Text>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px"
              }}>
                {document?.status == "analyzed" && (
                  <>
                    <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={onGenerate}> 
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: 'center',
                      }}>
                        <img src={process.env.PUBLIC_URL + "/assets/compare.png"} style={{ width: "20px", height: "20px" }} />
                        <Text size={16}>
                          Generate Comparison
                        </Text>
                      </div>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <Divider />
          
            {/* <iframe
              src={presignedUrl}
              width="100%"
              height="110%"
            /> */}
            { (localDocument?.instanceIds === undefined && localDocument?.category == "policy") && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                height: "110%",
                overflowY: "scroll",
                width: "100%"
              }}>
                {/* <Text size={30} weight={600}>
                  Associated Reports
                </Text>
                { documents.filter(d => d.instanceIds?.includes(document?.instanceId ?? "")).map(d => (
                  <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                    setDocument(d);
                  }} />
                ))} */}
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "110%",
                  overflowY: "scroll"
                }}>
                  <Text size={40} weight={600} align="center">
                    Comparison Analysis
                  </Text>
                  { loading && (
                    <>
                      <Space px={150} />
                      <Text size={40} color="gray" align="center">
                        Comparing policies
                      </Text>
                      <Space px={30} />
                      <Text size={20} color="gray" align="center">
                        If you're comparing these for the first time, it might take 5-10 minutes. Feel free to check back later.
                      </Text>
                      <Space px={50} />
                      <LoadingSpinner />
                    </>
                  )}
                  { (localDocument?.status === "analyzed" && !loading) && (
                    <>
                      <Space px={15} />
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "95%",
                        gap: "30px"
                      }}>
                        <Dropdown options={lineOptions.map(d => ({ value: d, label: d })) ?? []} option={selectedLine} setOption={setSelectedLine} label="Coverage Line" width="220px" />
                        <Dropdown options={[
                          {
                            label: "Key Information",
                            value: "generic"
                          },
                          {
                            label: "Coverage",
                            value: "coverage"
                          },
                          {
                            label: "Endorsement",
                            value: "endorsement",
                          },
                          {
                            label: "Schedule",
                            value: "schedule"
                          }]} option={selectedCoverageType} setOption={setSelectedCoverageType} label="Result Type" width="220px" />
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "95%",
                        gap: "10px"
                      }}>
                        <Space px={20} />
                        {filteredMatchedPairs.map((pair, i) => (
                          <PairItem
                            matchedPairId={pair.id}
                            firstResult={pair.firstVal}
                            secondResult={pair.secondVal}
                            firstName={localDocument?.name}
                            secondName={localSecondDocument?.name ?? ""}
                            firstPresignedUrl={presignedUrl}
                            secondPresignedUrl={secondPresignedUrl}
                            similar={pair.similar}
                            onClick={() => {
                              setSelectedPairId(pair.id);
                              setSelectedPairIndex(i);
                            }}
                            selected={pair.id == selectedPairId}
                            onDoubleClick={() => setSelectedPairId("")}
                            setRefresh={setRefreshEntries}
                            onScheduleClick={onScheduleClick}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}          
        </>
      )}
      { page == "markdown" && (
        <MarkdownPage result={scheduleResult} setPage={setPage} />
      )}
    </Modal>
  )
};

export default ComparisonModal;


