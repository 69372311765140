import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
//import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
 // test update
import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import Button from "./components/base/Button";
import { login, requestResetPassword, validateCookie } from "./requests";
import PasswordInput from "./components/base/PasswordInput";

const RequestResetPassword = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("overview");
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [username, setUsername] = useState<string>("");

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  const requestResetPass = async () => {
    setLoading(true);
    setErrorMessage("");
    const resp = await requestResetPassword(username);
    setSuccessMessage(
      <>
        <Text size={20}>
          If an account is associated with the below email, we have sent you a link to reset your password.
        </Text>
        <Space px={20} />
        <Text size={20} weight={600}>
          Make sure to check your spam folder!
        </Text>
      </>
    );
    setLoading(false);
  }

  return (
    <div style={{
      height: "100dvh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "40px",
        height: "min(70%, 400px)",
        width: "min(70%, 500px)",
        borderRadius: "16px",
        justifyContent: "center"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            width: "65px",
          }} src={ process.env.PUBLIC_URL + "/assets/new_logo.png" } />
          <Text size={50} weight={700} color="#4854F4">
            Coverflow
          </Text>
        </div>
        <Space px={30} />
        <Text size={40} weight={600} align="center">
          Enter your email to request a password reset.
        </Text>
        <Text size={20} weight={600}>
          Email
        </Text>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
          style={{
            width: "calc(100% - 70px)",
            borderRadius: "8px",
            fontSize: "20px",
            padding: "10px",
            border: "1px solid #475569",
            outline: "none",
            fontFamily: "Assistant, sans-serif",
          }}
        />
        <Space px={20} />
        { errorMessage && (
          <Text color="red" size={20}>
            {errorMessage}
          </Text>
        )}
        <Space px={10} />
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center"
        }}>
          { !loading && (
            <Button padding="12px" onClick={requestResetPass}>
              <Text size={20} color="white">
                Request Password Reset
              </Text>
            </Button>
          )}
          { loading && (
            <LoadingSpinner />
          )}
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/login")}>
            <Text size={16} color="#4854F4">
              Back to login.
            </Text>
          </div>
        </div>
      </div>


      <BottomBar setPrivacyModalOpen={setPrivacyModalOpen} setTermsModalOpen={setTermsModalOpen} displayRight={ page != "overview"} />
      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <ToastContainer />
    </div>
  )
};

export default RequestResetPassword;
