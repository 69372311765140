
import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import '@mdxeditor/editor/style.css'
import { MDXEditor, jsxPlugin, headingsPlugin, listsPlugin, quotePlugin, linkPlugin, toolbarPlugin, tablePlugin, markdownShortcutPlugin, frontmatterPlugin, thematicBreakPlugin, KitchenSinkToolbar, directivesPlugin } from '@mdxeditor/editor'

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Button from "./base/Button";
import { useCookies } from "react-cookie";
import LoadingSpinner from "./base/Spinner";
import Space from "./base/Space";
import { ResultWithReference } from "./PDFModal";
import './base/Button.css';
import { editCoverageEntry } from "../requests";

const MarkdownPage = ({ result, setPage }: { 
  result: ResultWithReference | null,
  setPage: (s: string) => void
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [editing, setEditing] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string>("");

  useEffect(() => {
    setSchedule(result?.keys["schedule"]);
  }, [result?.keys])

  const onSaveEdit = async () => {
    setEditing(false);
    await editCoverageEntry(cookies['user-id'], result?.id ?? "", { 'schedule': schedule }, result?.coverageName ?? "");
  }

  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: 'center',
          justifyContent: "flex-start",
          width: "90%",
          gap: "20px"
        }}>
          <img style={{ width: "70px", height: "70px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("normal")} />
          <Text size={35} weight={600}>
            {result?.coverageName ?? ""}
          </Text>
        </div>
      </div>
      <Divider />
      { !editing && (
        <div style={{ position: "relative" }}>
          <img style={{ position: "absolute", top: 0, right: 0, height: "32px", width: "32px", cursor: "pointer" }} src={process.env.PUBLIC_URL + "/assets/edit.png"} onClick={() => setEditing(true)} />
          <Text size={16}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{schedule}</ReactMarkdown>
          </Text>
        </div>
      )}
      { editing && (
        <>
          <MDXEditor markdown={schedule} plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), linkPlugin(), tablePlugin(), directivesPlugin(), jsxPlugin(), thematicBreakPlugin(), markdownShortcutPlugin(), frontmatterPlugin(), toolbarPlugin({
            toolbarContents: () => (
              <>
                {' '}
                <KitchenSinkToolbar />
              </>
            )
          })]} onChange={setSchedule} />
        </>
      )}
      { editing && (
        <div style={{ position: "sticky", bottom: "30px", right: "30px", marginLeft: "auto", display: "flex", flexDirection: "row", gap: "20px" }}>
          <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
            setSchedule(result?.keys["schedule"]);
            setEditing(false);
          }}>
            <Text size={20} weight={600} color="#000000">
              Cancel
            </Text>
          </Button>
          <Button width="200px" height="55px" onClick={onSaveEdit}>
            <Text size={20} weight={600} color="white">
              Save
            </Text>
          </Button>
        </div>
      )}
    </>
  )
};

export default MarkdownPage;

