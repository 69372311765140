import React, { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Tooltip } from 'react-tooltip'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import 'react-tooltip/dist/react-tooltip.css';

export function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {/* Background circular outline */}
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: (theme) =>
            theme.palette.grey[300], // Adjust this for the desired faint color
          position: 'absolute',
          zIndex: 0,
        }}
      />
      {/* Foreground progress */}
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color: (theme) =>
            props.value == 100 ? "#4cb543" : theme.palette.primary.main, // Adjust this for the primary progress color
          position: 'relative',
          zIndex: 1,
        }}
      />
      {/* Label in the center */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary', fontSize: props.value == 100 ? '0.65rem' : '0.8rem' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}