import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { useCookies } from "react-cookie";

import CopyIcon from './CopyIcon';
import Text from './base/Text';
import Button from './base/Button';
import Input from './Input';
import { analysisFeedback } from '../requests';

const FeedbackFooter = ({ documentInstanceId }: { documentInstanceId: string }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [page, setPage] = useState<string>("select");
  const [details, setDetails] = useState<string>("");
  const [isVisible, setIsVisible] = useState(true);

  const fadeDuration = 300; // Duration in milliseconds

  // Function to handle page transitions with fade effect
  const changePage = (newPage) => {
    setIsVisible(false);
    setTimeout(() => {
      setPage(newPage);
      setIsVisible(true);
    }, fadeDuration);
  };

  const onSubmitSuccess = async () => {
    changePage("success");
    await analysisFeedback(cookies['user-id'], documentInstanceId, true);
  }

  const onSubmitFeedback = async () => {
    changePage("thanks");
    await analysisFeedback(cookies['user-id'], documentInstanceId, false, details);
  }

  useEffect(() => {
    setPage("select");
    setDetails("");
  }, [documentInstanceId])

  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setPage("select");
      setDetails("");
      setIsVisible(true);
    }, fadeDuration);
  }, [documentInstanceId]);

  return (
    <div style={{
      position: "sticky",
      marginTop: "auto",
      bottom: "0px",
      width: "calc(100% - 20px)",
      padding: "10px",
      backgroundColor: "white",
      borderTop: "1px solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "10px",
      opacity: isVisible ? 1 : 0,
      transition: `opacity ${fadeDuration}ms`,
    }}>

        { page == "select" && (
          <>
            <Text size={20} align="center">
              How did we do on this generation?
            </Text>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
              <Button border="1px solid black" borderRadius="100px" padding="10px" color="white" onClick={onSubmitSuccess}>
                <img style={{ width: "20px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/thumbs-up.png"} />
              </Button>
              <Button border="1px solid black" borderRadius="100px" padding="10px" color="white" onClick={() => changePage("feedback")}>
                <img style={{ width: "20px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/thumbs-down.png"} />
              </Button>
            </div>
          </>
        )}
        { page == "success" && (
          <Text size={20} align="center">
            We're glad to hear that! We hope you have a great day.
          </Text>
        )}
        { page == "feedback" && (
          <>
            <Text size={20} align="center">
              We're sorry to hear that. How could we have done better?
            </Text>
            <Input inputType="textarea" rows={4} value={details} setValue={setDetails} extraStyles={{ border: "1px solid black", padding: "10px", borderRadius: "16px", fontSize: "14px", width: "calc(100% - 25px)" }} />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "flex-end", marginRight: "20px" }}>
              <Button border="1px solid black" borderRadius="8px" padding="10px" color="white" onClick={() => changePage("select")}>
                <Text size={12}>Cancel</Text>
              </Button>
              <Button border="1px solid black" borderRadius="8px" padding="10px" onClick={onSubmitFeedback}>
                <Text size={12} color="white">Submit</Text>
              </Button>
            </div>
          </>
        )}
        { page == "thanks" && (
          <>
            <Text size={20} align="center">
              Thank you for the feedback. We'll review it as soon as possible and reach out with any questions.
            </Text>
          </>
        )}
    </div>
  );
};

export default FeedbackFooter;

