import React, { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import 'react-tooltip/dist/react-tooltip.css'

import Text from "./base/Text";
import Button from "./base/Button";
import Space from "./base/Space";
import { analyzePolicy, autofillDocument, getPresignedUrl, retryReportRun } from "../requests";
import ReassignClient from "./ReassignClient";

import { DocumentStatus, DocumentType } from "./Document";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { Tooltip } from "react-tooltip";

function getWidth(reportInitiatedTime) {
  const now = new Date();
  const initiatedTime = new Date(reportInitiatedTime);
  const elapsedTime = (now.getTime() - initiatedTime.getTime()) / (1000 * 60); // Time elapsed in minutes

  if (elapsedTime >= 10) {
      return 102;
  } else if (elapsedTime <= 0) {
      return 0;
  } else {
      return (elapsedTime / 10) * 102;
  }
}

const ListDocument = ({ d, selecting, selected, retrieveDocuments, onClick, includePercent }: { d: DocumentType, selecting?: boolean, selected?: boolean, retrieveDocuments?: () => void, onClick?: (d: DocumentType) => void, includePercent?: boolean }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [periods, setPeriods] = useState('');
  const [displayName, setDisplayName] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [reportInitiated, setReportInitiated] = useState<number | null>(null);
  const [displayReassignClient, setDisplayReassignClient] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  
  useEffect(() => {
    if (d.status === DocumentStatus.UPDATING || d.status === DocumentStatus.UPLOADING || d.status === DocumentStatus.EXTRACTING || d.status === DocumentStatus.PARSING || d.status === DocumentStatus.ANALYZING || d.status === DocumentStatus.ANALYZING_POLICY) {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) => (prevPeriods.length < 3 ? prevPeriods + '.' : ''));
      }, 500);
  
      return () => clearInterval(interval);
    }
  }, [d.status]);

  const handleDownload = async () => {
    // Fetch the PDF from the URL
    console.log(d.instanceId)
    const resp = await getPresignedUrl(cookies['user-id'], d?.link ?? "", d.instanceId ?? "", (d.category == "comparison" || d.category == "analysis"), d.category == "proposal");

    console.log("URL RESPONSE FOR DOWNLOAD: " + resp.body["url"])
    const response = await fetch(resp.body["url"]);
    if (!response.ok) throw new Error('Failed to fetch PDF');
    
    // Create a Blob from the PDF stream
    const blob = await response.blob();
    // Create a local URL for the Blob
    const url = window.URL.createObjectURL(blob);
    
    // Create a temporary anchor element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = d.link?.endsWith(".xlsx")
      ? `${d.name}.xlsx`
      : d.category == "proposal" 
        ? `${d.name}.pptx`
        : `${d.name}.pdf`;
    document.body.appendChild(a); // Append to the document
    a.click(); // Trigger the download
    
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const onGenerate = async () => {
    await analyzePolicy(cookies['user-id'], "", d.instanceId ?? "", "external");
    retrieveDocuments && retrieveDocuments();
  }

  const onRetry = async () => {
    console.log("HERE1");
    await retryReportRun(cookies['user-id'], d.instanceId ?? "");
    console.log("HERE");
    retrieveDocuments && retrieveDocuments();
    console.log("RETRIEVED");
  }

  useEffect(() => {

    if (d.name?.endsWith("- Report")) {
      if (d.name.replace(" - Report", "").length > 60) {
        setDisplayName(
          d.name.replace(" - Report", "").slice(0, 60) + "... - Report"
        );
      } else {
        setDisplayName(
          d.name
        );
      }

    } else if (d.name?.endsWith("- Comparison")) {
      if (d.name.replace(" - Comparison", "").length > 60) {
        setDisplayName(
          d.name.replace(" - Comparison", "").slice(0, 60) + "... - Comparison"
        );
      } else {
        setDisplayName(
          d.name
        );
      }
    } else {
      if (d.name.length > 60) {
        setDisplayName(
          d.name.slice(0, 60) + "..."
        );
      } else {
        setDisplayName(
          d.name
        );
      }
    }

  }, [d])

  useEffect(() => {
    if (d.status == DocumentStatus.ANALYZING && reportInitiated === null) {
      const now = new Date();
      setReportInitiated(now.getTime());
    }

  }, [d.status])

  useEffect(() => {
    if (reportInitiated) {
        const interval = setInterval(() => {
            setWidth(getWidth(reportInitiated));
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }
  }, [reportInitiated]);

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      gap: "5px",
      padding: "5px 10px",
      //border: "1px solid gray",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "background-color 0.25s",
      backgroundColor: isHovered ? "#E0E0E0" : "#F6F6F6",
      border: selected ? "2px solid black" : undefined
    }}
      onClick={() => !selecting && d.category != "proposal" && !d.link?.endsWith(".xlsx") && onClick && onClick(d)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{
        width: "5%"
      }}>
        <img
          style={{ width: "30px", cursor: "pointer" }}
          src={ process.env.PUBLIC_URL + (d.link?.endsWith(".xlsx") ? "/assets/xlsx.png" : d.category == "proposal" ? "/assets/ppt.png" : "/assets/pdf.png")}
        />
      </div>
      <div style={{
        width: includePercent ? "40%" : "50%"
      }}>
        <Text size={20} align="left">{displayName}</Text>
      </div>
      <div style={{
        width: includePercent ? "13%" : "15%"
      }}>
        { d.status === DocumentStatus.UPDATING && (
          <>
            <Space px={7} />
            <Text size={14}>
              Updating{periods}
            </Text>
          </>
        )}
        { d.status === DocumentStatus.UPLOADING && (
          <>
            <Text size={14}>
              Uploading{periods}
            </Text>
          </>
        )}
        { d.status === DocumentStatus.FAILED && (
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Text size={14} color="red">
              Failed
            </Text>
            <img
                style={{ width: "14px", height: "14px", cursor: "pointer" }}
                src={process.env.PUBLIC_URL + `/assets/refresh.png`}
                onClick={(e) => {
                  e.stopPropagation();
                  onRetry();
                }}
              />
          </div>
        )}
        { (d.status === DocumentStatus.GENERATED) && (
          <div style={{ marginTop: "0px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", cursor: "pointer" }}>
              <Text size={14}>
                Generated
              </Text>
              <img
                style={{ width: "14px", height: "14px" }}
                src={process.env.PUBLIC_URL + `/assets/complete.png`}
              />
            </div>
          </div>
        )}
        { d.status === DocumentStatus.ANALYZING && (
          <div>
            <Text size={14}>
              {width < 100 ? "Generating" : "Finalizing"}{periods}
            </Text>
          </div>
        )}
        { d.status === DocumentStatus.ANALYZING_POLICY && (
          <>
            <div style={{ marginRight: "10px" }}>
              <Text size={14}>
                Analyzing{periods}
              </Text>
            </div>
          </>
        )}
        { (d.status === DocumentStatus.UPLOADED) && (
          <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", cursor: "pointer" }}>
              <Text size={14}>
                Uploaded
              </Text>
              <img
                style={{ width: "14px", height: "14px" }}
                src={process.env.PUBLIC_URL + `/assets/upload.png`}
              />
            </div>
          </>
        )}
        { (d.status === DocumentStatus.ANALYZED) && (
          <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", cursor: "pointer" }}>
              <Text size={14}>
                Analyzed
              </Text>
              <img
                style={{ width: "14px", height: "14px" }}
                src={process.env.PUBLIC_URL + `/assets/complete.png`}
              />
            </div>
          </>
        )}
      </div>
      <div style={{
        width: "15%"
      }}>
        <Text size={12}>
          {d.createdAt}
        </Text>
      </div>
      { includePercent && (
        <div style={{
          width: "12%",
          transform: "translateX(30%)"
        }}>
          { d.status == "analyzed" && (
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              //justifyContent: "center",
              alignItems: "center"
            }}>
              <CircularProgressWithLabel value={d.percentVerified ?? 0} /> 
              { d.badScan && (
                <>
                  <img
                    data-tooltip-id="bad-scan-tooltip"
                    data-tooltip-content="Some pages in this document weren't scanned well, so our OCR model struggled a bit. You might notice some imperfect output."
                    style={{ width: "30px", height: "30px", cursor: "pointer" }} src={process.env.PUBLIC_URL + `/assets/bad-output.png`} />
                  <Tooltip id="bad-scan-tooltip" place="top" style={{ maxWidth: "250px", whiteSpace: "normal", fontFamily: "Assistant", zIndex: 1000 }} />
                </>
              )}
            </div>
                  
          )}
        </div>
      )}
      <div style={{ width: "15%", display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "20px", position: "relative" }}>
        {!selecting && <img
          style={{ width: "23px", height: "23px", cursor: "pointer" }}
          src={ process.env.PUBLIC_URL + "/assets/download.png"}
          onClick={(d.status !== DocumentStatus.ANALYZING) ? (e) => {
            e.stopPropagation();
            handleDownload();
          } : undefined}
        />}
        {!selecting && d.category == "policy" && <img
          style={{ width: "23px", height: "23px", cursor: "pointer" }}
          src={ process.env.PUBLIC_URL + "/assets/right-arrow.png"}
          onClick={(e) => {
            e.stopPropagation();
            setDisplayReassignClient(!displayReassignClient);
          }}
        />}
        { displayReassignClient && (
          <ReassignClient d={d} retrieveDocuments={retrieveDocuments} isList />
        )}
      </div>
    </div>
  )
};

export default ListDocument;

