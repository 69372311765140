import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
//import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import Button from "./components/base/Button";
import { resetPassword, validateResetPasswordCode } from "./requests";


const ResetPassword = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("loading");
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);

  const [accessCode, setAccessCode] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [secondPassword, setSecondPassword] = useState<string>("");
  const [internalError, setInternalError] = useState<string>("");

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const { reset_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  useEffect(() => {

    async function validateResetCode() {
      const isValid = await validateResetPasswordCode(reset_id ?? "");
      if (isValid.body["valid"]) {
        setSuccessMessage("Your link was approved.");
        setPage("resetPassword");
      } else {
        setPage("invalidCode");
      }
    }

    if (reset_id) {
      validateResetCode();
    }
    
  }, [reset_id])

  useEffect(() => {
    async function redirectOnSuccess() {
      if (page == "success") {
        await new Promise(r => setTimeout(r, 3000));
        navigate("/login");
      }
    }

    redirectOnSuccess();
  }, [page])

  useEffect(() => {
    if (secondPassword.length > 0 && password != secondPassword) {
      setInternalError("Your passwords do not match");
    } else if (secondPassword.length > 0 && secondPassword.length < 8) {
      setInternalError("Your password must be at least 8 characters, and must contain at least: one number, one uppercase letter, and one lowercase letter.");
    } else {
      setInternalError("");
    }
  }, [secondPassword, password])

  async function resetPass() {
    setPage("loading");
    const resp = await resetPassword(reset_id ?? "", password);
    if (resp.status == 200) {
      setPage("success");
    } else if (resp.status == 400) {
      setInternalError("Your password must be at least 8 characters, and must contain at least: one number, one uppercase letter, and one lowercase letter.");
      setPage("createAccount");
    } else {
      setErrorMessage("We encountered an error creating your account. Please try again.");
      setPage("createAccount");
    }
  }

  return (
    <div style={{
      height: "100dvh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "40px",
        height: "min(70%, 400px)",
        width: "min(70%, 500px)",
        borderRadius: "16px",
        justifyContent: "center"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            width: "65px",
          }} src={ process.env.PUBLIC_URL + "/assets/new_logo.png" } />
          <Text size={50} weight={700} color="#4854F4">
            Coverflow
          </Text>
        </div>
        { page == "loading" && (
          <LoadingSpinner />
        )}
        { page == "invalidCode" && (
          <>
            <Text size={40} weight={600} align="center">
              Reset your password below.
            </Text>
            <Space px={30} />
            <Text size={20} align="center">
              The link you used is expired or invalid. Please request another link below.
            </Text>
            <Space px={30} />
            <Button padding="12px" width="150px" onClick={() => navigate("/request-reset-password")}>
              <Text size={20} color="white">
                Request Link
              </Text>
            </Button>
          </>
        )}
        { page == "success" && (
          <>
            <Space px={50} />
            <Text size={20} align="center">
              You have successfully reset your password. You will be redirected to the login page in 3 seconds.
            </Text>
          </>
        )}
        { page == "resetPassword" && (
          <>
            <Text size={40} weight={600} align="center">
              Reset your password below.
            </Text>
            <Text size={20} weight={600}>
              Password
            </Text>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && username.length != 0 && password.length >= 8 && password == secondPassword) {
                  resetPass();
                }
              }}
              style={{
                width: "calc(100% - 70px)",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "10px",
                border: "1px solid #475569",
                outline: "none",
                fontFamily: "Assistant, sans-serif",
              }}
            />
            <Text size={20} weight={600}>
              Validate Password
            </Text>
            <input
              type="password"
              value={secondPassword}
              onChange={(e) => setSecondPassword(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && username.length != 0 && password.length >= 8 && password == secondPassword) {
                  resetPass();
                }
              }}
              style={{
                width: "calc(100% - 70px)",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "10px",
                border: "1px solid #475569",
                outline: "none",
                fontFamily: "Assistant, sans-serif",
              }}
            />
            { internalError != "" && (
              <Text color="red" size={20}>
                {internalError}
              </Text>
            )}
            <Button padding="12px" width="150px" onClick={resetPass} greyed={password.length < 8 || password != secondPassword}>
              <Text size={20} color="white">
                Reset Password
              </Text>
            </Button>
          </>
        )}
        <Space px={10} />
      </div>
      <BottomBar setPrivacyModalOpen={setPrivacyModalOpen} setTermsModalOpen={setTermsModalOpen} displayRight={ page != "overview"} />
      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <ToastContainer />
    </div>
  )
};

export default ResetPassword;
