import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import Text from './base/Text';
import { ResultWithReference } from "./PDFModal";
import { addCoverageEntry, deleteCoverageEntry, editCoverageEntry } from '../requests';
import { useCookies } from 'react-cookie';
import Input from './Input';
import Button from './base/Button';

const NewResultItem = ({ coverageLine, coverageType, documentInstanceId, onClick, selected, onDoubleClick, setRefresh }: {
  coverageLine: string,
  coverageType: string,
  documentInstanceId: string,
  onClick: () => void,
  selected: boolean,
  onDoubleClick: () => void,
  setRefresh: (b: boolean) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [currentCoverageName, setCurrentCoverageName] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<string>("");
  const [currentSublimit, setCurrentSublimit] = useState<string>("");
  const [currentRetention, setCurrentRetention] = useState<string>("");
  const [currentDescription, setCurrentDescription] = useState<string>("");

  const resetValues = () => {
    setCurrentCoverageName("");
    setCurrentValue("")
    setCurrentRetention("");
    setCurrentDescription("");
  }

  const onSave = async () => {
    if (coverageType == "generic") {
      await addCoverageEntry(cookies['user-id'], currentCoverageName, coverageLine, coverageType, { "value": currentValue }, documentInstanceId);
    } else if (coverageType == "coverage") {
      await addCoverageEntry(cookies['user-id'], currentCoverageName, coverageLine, coverageType, { "limit": currentSublimit, "retention": currentRetention }, documentInstanceId);
    } else if (coverageType == "endorsement") {
      await addCoverageEntry(cookies['user-id'], currentCoverageName, coverageLine, coverageType, { "description": currentDescription }, documentInstanceId);
    }

    setRefresh(true);
    onDoubleClick();
    resetValues();
  }
  //                 <img src={process.env.PUBLIC_URL + "/assets/right-arrow.png"} style={{ cursor: "pointer", width: "20px", height: "20px", borderRadius: "8px", rotate: selected ? "90deg" : undefined}} onClick={() => setEditing(true)} />

  return (
    (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            //height: "40px",
            cursor: "pointer",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.25s",
            backgroundColor: selected ? "#E7E7E7" : (isHovered ? "#ECECEC" : "#F6F6F6"),
          }}
          onClick={selected ? onDoubleClick : onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}>
              { !selected && (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}>
                  <img src={process.env.PUBLIC_URL + "/assets/plus.png"} style={{ cursor: "pointer", width: "20px", height: "20px", borderRadius: "8px" }} />
                  <Text size={20} weight={selected ? 700 : 400}>
                    Add New Item
                  </Text>
                </div>
              )}
              { selected && (
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block" }}>
                    <Text size={20} weight={700}>
                      Name: 
                    </Text>
                  </div>
                  <div style={{ marginLeft: "5px", display: "inline-block" }}>
                    <Input value={currentCoverageName} setValue={setCurrentCoverageName} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700, backgroundColor: selected ? "#E7E7E7" : (isHovered ? "#ECECEC" : undefined), borderRadius: "0px" }} />
                  </div>
                </div>
              )}
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",
                marginRight: "30px"
              }}>
                { (isHovered || selected) && (
                  <>
                    { selected && (<img src={process.env.PUBLIC_URL + "/assets/cancel.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { 
                      //e.stopPropagation();
                      resetValues();
                      setEditing(false);
                    }} /> )}
                    { selected && (<img src={process.env.PUBLIC_URL + "/assets/green-check.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { e.stopPropagation(); onSave(); }} /> )}
                  </>
                )}
              </div>
              
            </div>
            
        </div>
        { selected && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderLeft: '3px solid grey',
            padding: "10px 30px",
            marginLeft: "15px",
          }}>
            { coverageType == "generic" && (
              <div style={{ display: "inline-block" }}>
                <div style={{ display: "inline-block" }}>
                  <Text size={20} weight={700}>
                    Value: 
                  </Text>
                </div>
                <div style={{ marginLeft: "5px", display: "inline-block" }}>
                  <Input value={currentValue} setValue={setCurrentValue} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700, borderRadius: "0px" }} />
                </div>
              </div>
            )}
            { coverageType == "coverage" && (
              <>
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block" }}>
                    <Text size={20} weight={600}>
                      Sublimit: 
                    </Text>
                  </div>
                  <div style={{ marginLeft: "5px", display: "inline-block" }}>
                    <Input value={currentSublimit} setValue={setCurrentSublimit} onClick={(e) => e.stopPropagation() } extraStyles={{ borderRadius: "0px" }} />
                  </div>
                </div>
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block" }}>
                    <Text size={20} weight={600}>
                      Retention: 
                    </Text>
                  </div>
                  <div style={{ marginLeft: "5px", display: "inline-block" }}>
                    <Input value={currentRetention} setValue={setCurrentRetention} onClick={(e) => e.stopPropagation() } extraStyles={{ borderRadius: "0px" }} />
                  </div>
                </div>
              </>
            )}
            { coverageType == "endorsement" && (
              <div style={{ display: "inline-block" }}>
                <div style={{ display: "inline-block" }}>
                  <Text size={20} weight={700}>
                    Description: 
                  </Text>
                </div>
                <div style={{ marginLeft: "5px", display: "inline-block" }}>
                  <Input inputType="textarea" value={currentDescription} setValue={setCurrentDescription} extraStyles={{ borderRadius: "0px" }} />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    )
  );
};

export default NewResultItem;
